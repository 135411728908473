"use client";

import { useRef } from "react";
import { Provider } from "react-redux";
import { makeStore } from "../lib/store/store";
// import { GoogleTagManager } from "@next/third-parties/google";

// import {clearCart} from '../lib/store/Slices/CartSlice';

function StoreProvider({ children }) {
  const storeRef = useRef(null);

  if (!storeRef.current) {
    storeRef.current = makeStore();
  }

  return (
    <Provider store={storeRef.current}>
      {/* <GoogleTagManager gtmId="GTM-K3PJDGHN" /> */}
      {children}
    </Provider>
  );
}

export default StoreProvider;
